body {
  background: url(bg_tile.png);
}

.app {
  position: relative;
  top: -100px;
}

a {
  color: black;
  text-shadow: black 1px 1px;
  font-size: 2rem;
}

[class|="retrato"] {
  position: relative;

  border: solid 0 transparent;
  border-radius: 100%;

  margin-left: auto;
  margin-right: auto;

  width: 30%;
  min-width: 200px;
  max-width: 500px;

  box-shadow: 0 2px 2px 0 gray, 0 0 0 10px white, 0 0 0 18px #f9a519,
    0 2px 10px 20px gray;
  z-index: 3;
  transform: scale(0.85) translatey(70%);
}

.retrato-cliente {
  box-shadow: 0 2px 2px 0 gray, 0 0 0 10px white, 0 0 0 18px #d1d3d4,
    0 2px 10px 20px gray;
}

.contenedor {
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  margin: auto;
  width: 90%;
  box-shadow: 0 0 5px 5px rgba(155, 155, 155, 0.8);
}

[class|="gradiente-horizontal"] {
  position: relative;
  width: 100%;
  height: 30px;
  background: linear-gradient(to right, #f4831f, #f9a519);
}

.gradiente-horizontal-cliente {
  background: #bcbec0;
}

.gradiente-horizontal-intermedio {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 15px;
  background: linear-gradient(to right, #f4831f, #f9a519);
}

#nombrePersonal {
  margin-top: 100px;
}

#primer-nombre {
  display: inline;
  font-size: 3em;
  color: black;
}

[id|="primer-apellido"] {
  display: inline;
  font-size: 3em;
  color: #f4831f;
}

#primer-apellido-cliente {
  color: #b1b3b6;
}

#titulo {
  text-align: center;
  font-size: 1.5em;
}

#logo {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.presentacion {
  text-align: center;
}

/*
=================================
            ICONOS
=================================
*/

.icono {
  position: relative;
  margin: 5px;
  width: 20px;
  height: 20px;
}

.icono.ubicacion {
  width: 15px;
  margin-left: 8px;
  margin-right: 8px;
}

.icono.grande {
  width: 50px;
  height: 50px;
}

.icono.grande:hover {
  filter: sepia(1);
}

.icono.grande:active {
  filter: invert(100%);
}

.renglon-telefono,
.renglon-mobile,
.renglon-ubicacion,
.renglon-mail {
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.social-icons {
  position: relative;
  margin: 10%;
}

.qr-button {
  position: fixed;
  top: 20%;
  right: 0;
  width: 50px;
  height: 50px;
  background: white;
  border: solid 2px black;
  border-radius: 100%;
}

.qr-button:hover {
  transform: scale(1.1);
}

.qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: black;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.5);
}

#qr-toggle:checked ~ .qr-overlay {
  pointer-events: auto;
  opacity: 0.9;
  transform: scale(1);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.qr-image {
  position: fixed;
  top: 40%;
  left: 50%;
  z-index: 100;
  width: 100%;
  max-width: 640px;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}

.qr-fb-share {
  background-color: #4080ff;
  border-radius: 5px;
}

.qr-fb-share:hover,
.qr-fb-share:active {
  background-color: #3070ff;
}

.qr-fb-logo {
  margin-left: 5px;
  margin-right: 5px;
}

.qr-fb-link {
  color: #fff;
  padding-right: 10px;
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  vertical-align: middle !important;
  text-shadow: none;
  position: relative;
  bottom: -2px;
}

.qr-fb-link:hover,
.qr-fb-link:active {
  color: #fff;
  text-decoration-line: none;
}

.qr-tw-share {
  position: fixed;
  bottom: 10%;
  left: 65%;
  z-index: 100;
  width: 100%;
  max-width: 640px;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}

.qr-wa-share {
  background-color: #25d366;
  border-radius: 5px;
}

.qr-wa-share:hover,
.qr-wa-share:active {
  background-color: #25c366;
}

.qr-share-buttons {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 10%;
  left: 50%;
  z-index: 101;
  width: 100%;
  max-width: 640px;
  height: 28px;
  pointer-events: none;
  opacity: 0;
}

.qr-wa-logo {
  /* padding-top: 3px; */
}

.qr-wa-link {
  color: #fff;
  padding-right: 10px;
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  vertical-align: middle !important;
  text-shadow: none;
  position: relative;
  bottom: -2px;
}

.qr-wa-link:hover,
.qr-wa-link:active {
  color: #fff;
  text-decoration: none;
}

.qr-mail-share {
  background-color: #fff;
  border-radius: 5px;
}

.qr-mail-share:hover,
.qr-mail-share:active {
  background-color: #ddd;
}

.qr-mail-link {
  padding-right: 10px;
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  vertical-align: middle !important;
  text-shadow: none;
  position: relative;
  bottom: -2px;
}

.qr-mail-link:hover,
.qr-mail-link:active {
  color: #000;
  text-decoration: none;
}

#qr-toggle:checked ~ label > .qr-image,
#qr-toggle:checked ~ label > .qr-fb-share,
#qr-toggle:checked ~ label > .qr-tw-share,
#qr-toggle:checked ~ label > .qr-wa-share,
#qr-toggle:checked ~ label > .qr-mail-share,
#qr-toggle:checked ~ label > .qr-share-buttons {
  pointer-events: auto;
  opacity: 1;
  transform: translate(-50%, -50%) scale(0.9);
  transition: transform 0.5s ease, opacity 0.5s ease;
  transition-delay: 0.1s;
}

/* 
=================================
                MAP
=================================
*/

.mapa {
  position: relative;
  margin: auto;
  margin-bottom: 20px;
  border: solid white 5px;
  width: 90%;
  box-shadow: 0 0 5px 5px rgba(155, 155, 155, 0.5);
}

/* 
=================================
              TABLET
=================================
*/

@media screen and (min-width: 768px) {
  #nombrePersonal {
    margin-top: 150px;
  }

  #primer-nombre,
  [id|="primer-apellido"] {
    font-size: 5em;
  }

  .gradiente-horizontal {
    height: 30px;
  }

  .gradiente-horizontal-intermedio {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 0.5rem;
  }
}

/* 
=================================
              DESKTOP
=================================
*/

@media screen and (min-width: 1280px) {
  .app {
    top: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .contenedor {
    margin-top: 150px;
    margin-left: 250px;
    margin-right: 100px;
    width: auto;
    position: relative;
    overflow: auto;
  }

  .nombreLogoContacto {
    display: flex;
    flex-direction: row;
  }

  .gradiente-horizontal {
    height: 30px;
  }

  .gradiente-horizontal-intermedio {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 0.5rem;
    display: none;
  }

  [class|="retrato"] {
    position: absolute;
    top: 50%;
    left: 50px;
    width: 300px;
    transform: translate(0, -50%);
  }

  .hemi {
    position: absolute;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0) 50%
    );
    border: solid 0 transparent;
    border-radius: 100%;
    height: 400px;
    width: 400px;

    top: 60%;
    left: 50px;
    transform: translate(0, -50%);
    z-index: 5;

    box-shadow: -4px 0 1px 0 rgba(155, 155, 155, 0.5);
  }

  #nombrePersonal {
    margin-top: auto;
  }

  .nombreYlogo {
    flex: 1.5;
  }

  .contacto {
    flex: 1;
  }

  .col {
    flex: 0.3;
  }

  #primer-nombre,
  [id|="primer-apellido"] {
    font-size: 5em;
  }

  #logo {
    width: 100%;
    max-width: 300px;
  }

  .mapa {
    max-width: 500px;
  }

  .social-icons {
    margin: 0;
  }

  div.social-icons a {
    flex: 1;
  }
}
